<template>
  <div class="store-list">
    <div class="top">
      <BreadCrumbb style="width: 1200px; margin: 0 auto"></BreadCrumbb>
    </div>
    <div class="main-card" style="padding-top: 30px" v-if="info">
      <div class="title">{{ info.company_info.company_name }}</div>
      <div class="tab-titel">
        <div style="width: 96px">序号</div>
        <div style="width: 270px">店铺名称</div>
        <div style="width: 270px">店铺账号</div>
        <div style="width: 270px">开通时间</div>
        <div style="width: 270px">站点</div>
        <div style="width: 270px">操作</div>
      </div>
      <div class="tab-list">
        <div class="tab-list-item">
          <div style="width: 96px; color: #8c929b">{{ info.shop_id }}</div>
          <!-- {{info.shop_info}} -->
          <div style="width: 270px">
            {{ info.shop_info.shop_name ? info.shop_info.shop_name : "----" }}
          </div>
          <div style="width: 270px">
            {{
              info.shop_info.member_name ? info.shop_info.member_name : "----"
            }}
          </div>
          <div style="width: 270px">{{ info.shop_info.create_time }}</div>
          <div style="width: 270px">马来西亚</div>
          <div style="width: 270px">
            <span class="btn" @click="toShopCenter">前往卖家中心</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumbb from "@/components/BreadCrumbb";
import { inList } from "@/api/main.js";
import { toggleMerchant } from "@/api/shop.js";
import { removeSAuth } from "@/utils/auth.js";
export default {
  data() {
    return {
      info: "",
      mainId: "",
    };
  },
  components: {
    BreadCrumbb,
  },
  created() {
    let index = localStorage.ININDEX;
    inList().then((res) => {
      if (res.code == 1) {
        this.mainId = res.data.id;
        this.info = res.data.list[index];
      }
    });
  },
  methods: {
    toShopCenter() {
      toggleMerchant({
        id: this.info.shop_id,
      }).then((res) => {
        // if (res.code == 1) {

        // if (localStorage.CENTERINFO) {
        //   let obj = JSON.parse(localStorage.CENTERINFO);
        //   if (obj.constructor == Object) {
        //     if (obj.member_id && obj.member_id != this.mainId) {
        //       removeSAuth();
        //     }
        //     if (
        //       obj.shop_info.admin_id &&
        //       obj.shop_info.admin_id != this.mainId
        //     ) {
        //       removeSAuth();
        //     }
        //   }

        //   this.$router.push("shopCenter");
        // } else {
        //   this.$router.push("shopCenter?");
        // }
        // }

        if (res.code == 1) {
          this.$router.push("shopCenter");
        }
        else if (res.code == 5000) {
          let obj = JSON.stringify(res.data.shop_info)
          this.$router.push("shopCenter?is_auth=1&info=" + obj);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.store-list {
  .top {
    height: 50px;
    background: #f2f5fa;
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
  }

  .tab-titel {
    margin-top: 30px;
    font-size: 14px;
    color: #8c929b;
    display: flex;
  }

  .tab-list {
    margin-top: 10px;

    .tab-list-item {
      display: flex;
      height: 62px;
      line-height: 62px;
      font-size: 14px;
      border-bottom: 1px solid #e5e5e5;

      .btn {
        color: #4c7ab9;
        cursor: pointer;
      }

      .btn:hover {
        color: #085cd1;
      }
    }
  }
}
</style>